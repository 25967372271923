$(function ()  {
    var w = $(window).width();

    $('.priduct_list_item').closest('body').addClass('is_prod');
    $('.home_news').find('.news_item:nth-child(3)').addClass('main_news');
    $('.contact').closest('body').addClass('contact_page');



    //Фотографії
    $('.put_photo').each(function(index, element) {
        var
            $this = $(this),
            srcImg = $this.find('.hidden_photo').attr('src');

        $this.css({'backgroundImage' : 'url(' + srcImg + ')'});
    });



    //Позиція фону на головному екрані
    var
        posFireplace = (w - 1170) / 2 + 480;

    $('.main_screen').css({'background-position-x': posFireplace});

    setTimeout(function(){
        $('.main_screen').addClass('main_screen_bg');
    }, 100);

    $(window).resize(function(){
        var
            wAction = $(window).width(),
            posFireplaceAction = (wAction - 1170) / 2 + 480;

        $('.main_screen').css({'background-position-x': posFireplaceAction});
    });




    //Вибір товару
    $('.change_click').click(function() {

        var
            $this = $(this),
            thisInput = $this.closest('.change_prod_input'),
            otherInput = thisInput.siblings(),
            thisDropList = $this.closest('.change_prod_input').find('.change_prod_input_list');

        if($this.hasClass('readyChange')) {
            if(!$this.hasClass('active')) {
                thisDropList.slideDown(500);
                $this.addClass('active');
            } else {
                thisDropList.slideUp(500);
                $this.removeClass('active');
            }
        }
        otherInput.find('.change_prod_input_list').slideUp(500);
        otherInput.find('.change_click').removeClass('active');


        $('.change_prod_link').click(function(e) {
            e.preventDefault()

            var
                $this = $(this),
                thisInput = $this.closest('.change_prod_input'),
                thisItem = $this.closest('.change_prod_input_item'),
                thisName = $this.text(),
                prodOfCat = thisItem.find('.products_of_cat').clone();

            thisInput.find('.changed_prod').text(thisName);
            thisInput.find('.changed_prod').addClass('opNone');
            $('.change_prod .change_click').addClass('readyChange');
            setTimeout(function(){
                $('.change_prod_input_list').slideUp(500);
                $('.change_click').removeClass('active');
            }, 200);

            if(prodOfCat.length > 0) {
                $('.change_products').html(prodOfCat);
                $('.change_prod').find('.changed_prod').text('Выберете товар');
                $('.change_prod').find('.changed_prod').removeClass('opNone');
                $('.change_prod_button').attr('href', '#0');
            } else {
                var thisLink = $this.attr('href');

                $('.change_prod_button').attr('href', thisLink);
            }
        });

    });


    $(document).mouseup(function (e){
        var
            hideThis = $('.change_prod_input_list'),
            notHide = $('.change_prod_input_list, .change_prod_input');

        if (!hideThis.is(e.target)
            && notHide.has(e.target).length === 0) {
            hideThis.slideUp(500);
            $('.change_click').removeClass('active');
        }

    });




    //Замовити дзвінок
    $('.call_back').click(function(e) {
        e.preventDefault();

        $('#overlay').fadeIn(500);
        $('.call_back_popup').fadeIn(1000);
    });

    $('#overlay').click(function(e) {
        e.preventDefault();

        $('#overlay').fadeOut(1000);
        $('.call_back_popup').fadeOut(500);
    });





    //Паралакс
    if (w > 1170) {
        $(window).scroll(function () {
            var
                scrollTop = $(window).scrollTop(),
                windowHeight = $(window).height() / 2,
                center = scrollTop + windowHeight;

            if($('.about_section').length > 0) {
                var
                    aboutOffset = $('.about_section').offset(),
                    aboutOffsetTop = aboutOffset.top,
                    aboutParallax = center - aboutOffsetTop;

                $('.about_section').css({'background-position-y': aboutParallax / 3 - 200});
            }

            if($('.footer').length > 0) {
                var
                    footerOffset = $('.footer').offset(),
                    footerOffsetTop = footerOffset.top,
                    footerParallax = center - footerOffsetTop;

                $('.footer').css({'background-position-y': footerParallax / 2 - 450});
            }
            $('.main_screen').css({'background-position-y': center / 4 - 50});
            // $('.main_screen_other').css({'background-position-y': $(window).scrollTop() / 2 - 150});
            $('.information_right_photo').css({'background-position-y': center / 4 - 170});

        }).scroll();
    }



    //Карточка товару
    $('.product_cart_photos_item').click(function() {
        var
            $this = $(this),
            thisImg = $this.find('img').attr('src');

        $('.product_cart_big_inner').css({'backgroundImage' : 'url(' + thisImg + ')'});
        $this.addClass('active');
        $this.siblings().removeClass('active');
    });

    setTimeout(function(){
        $('.product_cart_photos_item:first-child').click();
    }, 100);



    //Питання і відповіді
    $('.questions_item').click(function() {

        var
            $this = $(this),
            thisAnswer = $this.find('.answer').html(),
            thisPosition = $this.position(),
            containerHeight = $('.questions_right').height();

        $this.addClass('active');
        $this.siblings().removeClass('active');
        $('.answer_for_quest_inner').html(thisAnswer);
        $('.answer_for_quest').css({'max-height' : containerHeight + 'px'});

        setTimeout(function(){
            var
                answerHeight = $('.answer_for_quest').height(),
                lastPosition = thisPosition.top + answerHeight,
                lastPositionIf = containerHeight - answerHeight;

            if(lastPosition > containerHeight) {
                $('.answer_for_quest').css({'top' : lastPositionIf + 'px'});
            } else {
                $('.answer_for_quest').css({'top' : thisPosition.top + 'px'});
            }
        }, 10);
    });

    $('.questions_item:first-child').click();



    //Форма питання
    $('.chackbox').click(function() {

        var
            $this = $(this),
            input = $this.find('input');

        if(!$this.hasClass('active')) {
            $this.addClass('active');
            input.prop( "checked", true );
        } else {
            $this.removeClass('active');
            input.prop( "checked", false );
        }

    });





    //Акордеон
    $('.accordeon__title').click(function(){

        var
            $this = $(this),
            item = $this.closest('.accordeon__item'),
            inform = item.find('.accordeon__text'),
            informs = $('.accordeon__text');


        if(item.hasClass('active')){
            inform.stop(!0, !0).slideUp(800);
            item.removeClass('active');
        }else {
            inform.stop(!0, !0).slideDown(800);
            item.addClass('active');
            item.siblings().removeClass('active');
            item.siblings().find(informs).stop(!0, !0).slideUp(800);
            item.siblings().removeClass('active');

        }
    });



});







function initMap() {
    // Styles a map in night mode.
    var map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: 61.246428, lng: 73.4915793},
        zoom: 14,
        styles: [
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    }
                ]
            }
        ]
    });


    var infoWindow = new google.maps.InfoWindow;

    var onMarkerClick = function() {
        var marker = this;
        var latLng = marker.getPosition();
        infoWindow.setContent(marker.title);

        infoWindow.open(map, marker);
    };
    google.maps.event.addListener(map, 'click', function() {
        infoWindow.close();
    });

    var onMarkermouseover = function() {
        var marker = this;
        var latLng = marker.getPosition();
        infoWindow.setContent(marker.title);

        infoWindow.open(map, marker);
    };
    google.maps.event.addListener(map, 'mouseover', function() {
        infoWindow.close();
    });


    var marker1 = new google.maps.Marker({
        position: {lat: 61.246428, lng: 73.4915793},
        map: map,
        title: '<div class="markerInform"><span>г.Сургут, ул. Инженерная 15</span></div>',
        icon: 'img/icons/marker.png',
        icon: '../img/icons/marker.png'
    });

    var marker2 = new google.maps.Marker({
        position: {lat: 60.957669, lng: 76.5764002},
        map: map,
        title: '<div class="markerInform"><span>г.Нижневартовск, ул. Северная 19В, стр.1</span></div>',
        icon: 'img/icons/marker.png',
        icon: '../img/icons/marker.png'
    });

    var marker3 = new google.maps.Marker({
        position: {lat: 61.030594, lng: 69.1446022},
        map: map,
        title: '<div class="markerInform"><span>г.Ханты-Мансийск, ул. Магистральная 5</span></div>',
        icon: 'img/icons/marker.png',
        icon: '../img/icons/marker.png'
    });

    // infoWindow.open(map, marker1);
    google.maps.event.addListener(marker1, 'mouseover', onMarkermouseover);
    google.maps.event.addListener(map, 'mouseover', onMarkermouseover);
    google.maps.event.addListener(marker2, 'mouseover', onMarkermouseover);
    google.maps.event.addListener(marker3, 'mouseover', onMarkermouseover);
    google.maps.event.addListener(marker1, 'click', onMarkerClick);
    google.maps.event.addListener(marker2, 'click', onMarkerClick);
    google.maps.event.addListener(marker3, 'click', onMarkerClick);
}


